import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"


const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <body className="">

        <nav>
          <Link to={'/'} className="title">EXCHANGE QUÉBEC</Link>

          <ul>
            <li>
              <Link to={'/blockchain-intro'}>Blockchain 101</Link></li>
            <li><Link to={'/start-intro'}>Blockchain Avancée et Sécurité</Link></li>
            <li><Link target="_blank" to={'https://www.youtube.com/channel/UC98-Y77NBn208JPQb5U-U1A'}>Youtube</Link></li>
          </ul>
        </nav>
        <header>
          {/*<GatsbyImage image={fluidImages} alt={'data.blogPost.author'} />*/}
          <StaticImage
            src="../images/header.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fullWidth"

          />
          <div className="content">
            <h1 className="title">Le futur de la crypto au Québec</h1>
          </div>
         </header>
        {children}
        <footer>© 2023 EXQC</footer>

    </body>)
}

export default Layout